code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Ubuntu-Bold';
  src: url('Assets/fonts/Ubuntu/Ubuntu-Bold.ttf')
}

@font-face {
  font-family: 'Ubuntu-Medium';
  src: url('Assets/fonts/Ubuntu/Ubuntu-Medium.ttf')
}

@font-face {
  font-family: 'Ubuntu-Light';
  src: url('Assets/fonts/Ubuntu/Ubuntu-Light.ttf')
}

@font-face {
  font-family: 'Ubuntu-Regular';
  src: url('Assets/fonts/Ubuntu/Ubuntu-Regular.ttf')
}


@font-face {
  font-family: 'Ubuntu-Bold';
  src: url('Assets/fonts/Ubuntu/Ubuntu-Bold.ttf')
}

@font-face {
  font-family: 'Ubuntu-Medium';
  src: url('Assets/fonts/Ubuntu/Ubuntu-Medium.ttf')
}

@font-face {
  font-family: 'Ubuntu-Light';
  src: url('Assets/fonts/Ubuntu/Ubuntu-Light.ttf')
}

@font-face {
  font-family: 'Ubuntu-Regular';
  src: url('Assets/fonts/Ubuntu/Ubuntu-Regular.ttf')
}

/* General reset and font settings */
body {
  margin: 0;
  font-family: 'Intel', 'Arial', sans-serif !important;
  font-size: 15px;
  width: 100%;
  background-color: #f4f6f8 !important;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

/* Ensure the html and body take up the entire viewport */
html,
body {
  height: 100%;
  /* Full height for the page */
  margin: 0;
  /* Remove default margin */
}

/* Page container to handle the flex layout */
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Ensure the container fills the entire viewport */
}

/* Content area to take up remaining space */
.content-wrap {
  flex-grow: 1;
  /* This will take up any extra space available */
}

/* Footer Styling */
.footer {
  background-color: #333;
  color: white;
  padding: 3px 0;
  text-align: center;
  flex-shrink: 0;
  /* Prevent the footer from shrinking */
}

/* Footer content styling */
.footer-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* Spread the content across the footer */
  align-items: center;
  /* Vertically center the content */
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}