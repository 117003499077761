:root {
    --vh: 100%;
}

.page-header {
    font-family: 'Ubuntu-Bold' !important;
    font-size: 20px;
    letter-spacing: 0px;
    color: #111 !important;
}

.b-width {
    width: 100px;
}

.grid-margin {
    margin-bottom: 60px;
}

.input-file {
    font-family: 'Ubuntu-Medium' !important;
    font-size: 13px;
    letter-spacing: 0px;
    color: #111 !important;
}

.ag-header-cell-label {
    font-family: 'Ubuntu-Medium' !important;
    font-size: 12px !important;
    font-weight: 100 !important;
}

.ag-theme-balham {
    font-family: 'Ubuntu-Medium' !important;
    font-size: 12px !important;
    color: #111 !important;
}

.btn-text {
    font-family: 'Ubuntu-Regular' !important;
    font-size: 13px !important;
    letter-spacing: 0px;
    color: #fff !important;
}

.btn-text-dark {
    font-family: 'Ubuntu-Regular' !important;
    font-size: 13px !important;
    letter-spacing: 0px;
    color: #111 !important;
}

.label-text {
    font-family: 'Ubuntu-Regular' !important;
    font-size: 13px !important;
    letter-spacing: 0px;
    color: #111 !important;
}

.input-text {
    font-family: 'Ubuntu-Regular' !important;
    font-size: 13px !important;
    letter-spacing: 0px;
    color: #111 !important;
}

.loadspinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cd-header {
    font-family: 'Ubuntu-Medium' !important;
    font-size: 16px;
    letter-spacing: 0px;
    color: #111 !important;
}

.cd-subheader {
    font-family: 'Ubuntu-Medium' !important;
    font-size: 11px;
}



.cd-value {
    font-family: 'Ubuntu-Medium' !important;
    font-size: 16px;
    letter-spacing: 0.075em;
    color: #111 !important;
}

.res-padding {
    padding-left: 20px;
    padding-right: 20px;
}

.investment-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.investment-item {
    padding: 8px 0;
    border-bottom: 1px solid #f0f0f0;
}

.investment-item:last-child {
    border-bottom: none;
}

.investment-name {
    font-size: 13px;
    font-family: 'Ubuntu-Regular', sans-serif !important;
}

.investment-value {
    color: #111;
    font-size: 13px;
    font-family: 'Ubuntu-Regular', sans-serif !important;
}

.investment-total {
    color: #111;
    font-size: 13px;
    font-family: 'Ubuntu-Bold', sans-serif !important;
}

.transaction-in {
    color: #111;
    font-size: 16px;
    font-family: 'Ubuntu-Medium', sans-serif !important;
}

.transaction-header {
    font-size: 12px;
    font-family: 'Ubuntu-Medium', sans-serif !important;
}

.transaction-out {
    color: #b22222;
    font-size: 16px;
    font-family: 'Ubuntu-Medium', sans-serif !important;
}

.transaction-out-icon {
    color: #ff8080;
}

.transaction-in-icon {
    color: #a6a6a6;
}



.total-section {
    font-size: 13px;
    font-family: 'Ubuntu-Regular', sans-serif !important;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width:1000px) {
    .res-padding {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media screen and (max-width:767px) {
    .cd-value {
        font-size: 13px;
    }

    .cd-subheader {
        font-size: 10px;
    }

    .ag-theme-balham {
        font-size: 11px !important;
    }

    .transaction-in {
        font-size: 14px;
    }

    .transaction-out {
        font-size: 14px;
    }

    .hide-xs {
        display: none;
    }

    .investment-value {
        font-size: 12px;
    }

    .investment-total {
        font-size: 12px;
    }

    .investment-name {
        font-size: 12px;
    }
}

@media screen and (max-width:450px) {
    .cd-value {
        font-size: 12px;
    }

    .cd-subheader {
        font-size: 9px;
    }


}

.contact-cta-button {
    background-color: #1f81d6;
    color: #fff;
    border-radius: 0px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    padding: 0.7rem 1.5rem;
    font-size: 1.1rem;
    border: 0px;
    text-decoration: none;
}

.contact-cta-button:hover {
    background-color: darken(#1f81d6, 2%);
    transform: scale(1.03);
    color: #fff;
}