.nav-brand {
  font-family: 'Ubuntu-Medium' !important;
  font-size: 20px;
  letter-spacing: 0px;
  color: #fff !important;
}

.nav-text {
  font-family: 'Ubuntu-Medium' !important;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff !important;
}

.nav-text-2 {
  font-family: 'Ubuntu-Medium' !important;
  font-size: 14px;
  letter-spacing: 1px !important;
  color: #111 !important;
  text-decoration: none !important;

}

.bg-Nav {
  background-color: #225560;
}

.nav-header1 {
  font-size: "14px" !important;
  letter-spacing: "0px" !important;
}

.nav-pointer1 {
  cursor: pointer !important;
}