.footer {
    background-color: #111;
}

.footer-text {
    font-family: 'Ubuntu-Bold' !important;
    font-size: 11px;
    letter-spacing: 0px;
    color: #c1c1c1;
    letter-spacing: 0.075em;
}